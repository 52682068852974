import {
  Activity,
  CountResponse,
  Dictionary,
  Level,
  Media,
  MediaPaginationParams,
  PutCommand,
  Section,
  Unit,
  UnitModule,
  User,
  Word,
} from "./types";

const ErrUnauthorized = new Error("Unauthorized");
const ErrServerError = new Error("Server Error");

type QueryParams = Record<string, string>;

// Api class for interacting with backend
class Api {
  user?: User;
  private access_token?: string;
  private refresh_token?: string;
  private baseUrl: string;

  constructor(baseUrl: string = "") {
    if (baseUrl.endsWith("/")) {
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    this.baseUrl = baseUrl;
    this.user = this.getUser();
    this.access_token = this.getAccessToken();
    this.refresh_token = this.getRefreshToken();
  }

  async listLevels(): Promise<Level[]> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + "/api/levels", {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  async addLevel(level: Level): Promise<Level> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + "/api/levels", {
        method: "post",
        body: JSON.stringify(level),
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  async editLevel(id: string, level: Level): Promise<Level> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/levels/${id}`, {
        method: "PATCH",
        body: JSON.stringify(level),
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  async listUsers(): Promise<User[]> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + "/api/users", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      });
    });
  }

  async removeLevel(id: string): Promise<CountResponse> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/levels/${id}`, {
        method: "delete",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  async listSections(): Promise<Section[]> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/sections`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      })
    );
  }

  async listSectionsByLevel(id: string): Promise<Section[]> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/levels/${id}/sections`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      })
    );
  }

  async findSection(id: string): Promise<Section> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/sections/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      })
    );
  }

  async addSectionToLevel(levelId: string, section: Section): Promise<Section> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/levels/${levelId}/sections`, {
        method: "POST",
        body: JSON.stringify(section),
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  async editSection(id: string, section: Section): Promise<Section> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/sections/${id}`, {
        method: "PATCH",
        body: JSON.stringify(section),
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      })
    );
  }

  async removeSection(id: string): Promise<Section> {
    return this.withRefresh(() => {
      return fetch(`/api/sections/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      });
    });
  }

  async listUnits(): Promise<Unit[]> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/units`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async listUnitsBySection(id: string): Promise<Unit[]> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/sections/${id}/units`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      })
    );
  }

  async findUnit(id: string): Promise<Unit> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/units/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async addUnitToSection(id: string, unit: Unit): Promise<Unit> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/sections/${id}/units`, {
        method: "POST",
        body: JSON.stringify(unit),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async removeUnit(id: string): Promise<CountResponse> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/units/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      });
    });
  }

  async editUnit(id: string, unit: Unit): Promise<Unit> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/units/${id}`, {
        method: "PATCH",
        body: JSON.stringify(unit),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
      });
    });
  }

  async updateActivities(
    unitId: string,
    module: UnitModule,
    activities: Activity[]
  ): Promise<CountResponse> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/units/${unitId}/activities/${module}`, {
        method: "PUT",
        body: JSON.stringify(activities),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
      });
    });
  }

  async updateDictionaryByUnit(
    unitId: string,
    dict: Word[]
  ): Promise<CountResponse> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/units/${unitId}/dictionary`, {
        method: "PUT",
        body: JSON.stringify(dict),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async listDictionaryByUnit(unitId: string): Promise<Dictionary> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/units/${unitId}/dictionary`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async listActivitiesByUnitModule(
    unitId: string,
    module: UnitModule
  ): Promise<Activity[]> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/units/${unitId}/activities/${module}`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async addActivityToUnit(
    unitId: string,
    module: UnitModule,
    activity: Activity
  ): Promise<Activity> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/units/${unitId}/activities/${module}`, {
        method: "post",
        body: JSON.stringify(activity),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async editActivity(id: string, activity: Activity): Promise<Activity> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/activities/${id}`, {
        method: "PATCH",
        body: JSON.stringify(activity),
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
      })
    );
  }

  async removeActivity(id: string): Promise<CountResponse> {
    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/activities/${id}`, {
        method: "delete",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      });
    });
  }

  // TODO: remove htis
  async createActivity<T>(type: string, activity: object): Promise<T> {
    return this.withRefresh(() =>
      fetch(this.baseUrl + `/api/activities/${type}`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(activity),
      })
    );
  }

  async login(email: string, password: string) {
    const response = await fetch(this.baseUrl + "/auth/login", {
      method: "post",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.status == 401) {
      throw ErrUnauthorized;
    }

    const data = await response.json();

    if (data.error) {
      throw new Error(data.error);
    }

    if (!data.user.roles.includes("admin")) {
      throw ErrUnauthorized;
    }

    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("user", JSON.stringify(data.user));

    this.access_token = data.access_token;
    this.refresh_token = data.refresh_token;
    this.user = data.user;
  }

  isAuthenticated(): boolean {
    console.log("access token", this.access_token, "user", this.user);
    return !!this.access_token && !!this.user;
  }

  async paginateMedia(
    type: "image" | "audio",
    lastId?: string,
    limit: number = 25,
    search?: string
  ): Promise<Media[]> {
    const paginationParams: MediaPaginationParams = { type, limit };

    if (lastId) {
      paginationParams.lastId = lastId;
    }

    if (search) {
      paginationParams.search = search;
    }

    const searchParams = new URLSearchParams({
      ...paginationParams,
      limit: paginationParams.limit.toFixed(0).toString(),
    });

    const url = `${this.baseUrl}/api/media?${searchParams}`;

    return this.withRefresh<Media[]>(() =>
      fetch(url, {
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async updateMedia(media: Media, file: File) {
    const form = new FormData();
    form.append(media.type, file);

    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/media/${media._id}`, {
        method: "put",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
        body: form,
      });
    });
  }

  async uploadMedia(file: File, param: string) {
    const form = new FormData();
    form.append(param, file);
    return this.withRefresh(() => {
      return fetch(this.baseUrl + `/api/upload/${param}`, {
        method: "post",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
        body: form,
      });
    });
  }

  async deleteMedia(id: string) {
    this.withRefresh(() =>
      fetch(this.baseUrl + `/api/media/${id}`, {
        method: "delete",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  // these basically execute mongo queries which is bad
  async updateRecord(path: string, cmd: PutCommand) {
    return this.withRefresh<CountResponse>(() =>
      fetch(this.baseUrl + "/api/records" + path, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
        body: JSON.stringify(cmd),
      })
    );
  }

  async deleteRecord(path: string, query: QueryParams) {
    const params = new URLSearchParams(query);
    const url = this.baseUrl + "/api/records" + path + "?" + params.toString();
    return this.withRefresh<CountResponse>(() =>
      fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async postRecord<T>(path: string, data: Partial<T>): Promise<T> {
    return this.withRefresh<T>(() =>
      fetch(this.baseUrl + "/api/records" + path, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token}`,
        },
        body: JSON.stringify(data),
      })
    );
  }

  async getRecords<T>(path: string, query: QueryParams): Promise<T[]> {
    const params = new URLSearchParams(query);
    const url = this.baseUrl + "/api/records" + path + "?" + params.toString();

    return this.withRefresh<T[]>(() =>
      fetch(url, {
        headers: {
          Authorization: `Bearer ${this.access_token}`,
        },
      })
    );
  }

  async withRefresh<T>(fetchFunc: () => Promise<Response>): Promise<T> {
    let res = await fetchFunc();

    if (res.status == 403) {
      // will throw ErrUnauthorized if not ok
      try {
        await this.refresh();
      } catch (err) {
        window.location.href = "/login";
      }

      res = await fetchFunc();
    }

    if (!res.ok) {
      console.error(res);
      throw ErrServerError;
    }

    const data = await res.json();
    return data as T;
  }

  async refresh() {
    if (!this.refresh_token) {
      this.clearLocalData();
      throw ErrUnauthorized;
    }

    const response = await fetch(this.baseUrl + "/auth/refresh", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: this.refresh_token,
      }),
    });

    // we could not refresh data
    if (!response.ok) {
      this.clearLocalData();
      throw ErrUnauthorized;
    }

    const data = await response.json();
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("access_token", data.access_token);
    this.access_token = data.access_token;
    this.refresh_token = data.refresh_token;
  }

  getUser(): User | undefined {
    const user = localStorage.getItem("user");
    if (!user) {
      return undefined;
    }

    try {
      return JSON.parse(user) as User;
    } catch {
      return undefined;
    }
  }

  getAccessToken(): string {
    if (this.access_token) {
      return this.access_token;
    }

    const at = localStorage.getItem("access_token");
    if (!at) return "";
    return at;
  }

  getRefreshToken(): string {
    const at = localStorage.getItem("refresh_token");
    if (!at) return "";
    return at;
  }

  clearLocalData() {
    this.user = undefined;
    localStorage.removeItem("user");

    this.access_token = undefined;
    localStorage.removeItem("access_token");

    this.refresh_token = undefined;
    localStorage.removeItem("refresh_token");
  }
}

// @ts-expect-error for testing
window.api = new Api();

export default new Api();
