import { createElement, lazy } from "react";
import { createBrowserRouter, redirect } from "react-router-dom";
import Login from "./pages/Login";

const index = lazy(() => import("./pages/Index"));
const admin = lazy(() => import("./layouts/AdminLayout"));
const users = lazy(() => import("./pages/Users"));
const media = lazy(() => import("./pages/Media"));
const section = lazy(() => import("./pages/Section"));
const mod = lazy(() => import("./pages/Module"));
const level = lazy(() => import("./pages/Level"));
const config = lazy(() => import("./pages/Configuration"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: createElement(admin),

    children: [
      {
        path: "/admin/",
        element: createElement(index),
      },
      {
        path: "/admin/usuarios",
        element: createElement(users),
      },
      {
        path: "/admin/media/:type",
        element: createElement(media),
      },
      {
        path: "/admin/niveles/:levelId",
        element: createElement(level),
      },
      {
        path: "/admin/secciones/:sectionId",
        element: createElement(section),
      },
      {
        path: "/admin/unidades/:unitId/:module",
        element: createElement(mod),
      },
      {
        path: "/admin/configuracion",
        element: createElement(config),
      },
    ],
  },
  {
    path: "*",
    loader: () => redirect("/admin/"),
  },
]);
