import { App as AntApp, ConfigProvider, theme } from "antd";
import locale from "antd/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es-mx";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { useThemeStore } from "./stores/theme.store";
import React from "react";
dayjs.locale("es-mx");

export const AppConfig = (props: { children: React.ReactElement }) => {
  const mode = useThemeStore((t) => t.mode);
  return (
    <ConfigProvider
      locale={locale}
      form={{
        validateMessages: {
          required: "${label} es requerido",
          default: "Este campo tiene un error",
        },
      }}
      theme={{
        algorithm: [
          mode == "dark" ? theme.darkAlgorithm : theme.defaultAlgorithm,
        ],
      }}
    >
      <AntApp>{props.children}</AntApp>
    </ConfigProvider>
  );
};

export function App() {
  return (
    <AppConfig>
      <RouterProvider router={router} />
    </AppConfig>
  );
}
