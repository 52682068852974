
import { create } from "zustand";

type ThemeMode = "dark" | "light";

interface ThemeStore {
  mode: ThemeMode;
  setMode(mode: ThemeMode): void;
}

export const useThemeStore = create<ThemeStore>((set) => ({
  mode: (localStorage.getItem("themeMode") as ThemeMode) || "light",
  setMode(mode: ThemeMode) {
    localStorage.setItem("themeMode", mode);
    set(() => ({ mode: mode }));
  },
}));
