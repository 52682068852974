import { LockOutlined, SendOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Layout, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import api from "../api";

interface LoginForm {
  organization: string;
  email: string;
  password: string;
}

const Login = () => {
  const [form] = Form.useForm<LoginForm>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: LoginForm) => {
    try {
      setLoading(true);
      await api.login(values.email, values.password);
      navigate("/admin");
    } catch (err) {
      form.setFields([
        {
          name: "email",
          errors: ["Usuario o contraseña incorrecto"],
        },
        {
          name: "password",
          errors: ["Usuario o contraseña incorrecto"],
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <Card
          style={{
            margin: "auto",
            width: "100%",
            maxWidth: "480px",
          }}
        >
          <Card.Meta title={<img src="/logo.png" alt="logo" />} />
          <Form layout="vertical" form={form} onFinish={onSubmit}>
            <Form.Item
              name="email"
              label="Usuario"
              rules={[{ required: true }]}
            >
              <Input
                prefix={<UserOutlined />}
                autoComplete="email"
                placeholder="usuario"
                name="email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Contraseña"
              rules={[{ required: true }]}
            >
              <Input.Password
                autoComplete="current-password"
                placeholder="*********"
                prefix={<LockOutlined />}
              />
            </Form.Item>
            <Button
              style={{ marginTop: "1rem" }}
              icon={<SendOutlined />}
              block
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Enviar
            </Button>
          </Form>
        </Card>
      </div>
    </Layout>
  );
};

export default Login;
